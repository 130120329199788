import React, { useContext, useEffect, useState } from "react"
import Heading from "~/components/configurable/Heading"
import StatsBlock from "~/components/standalone/StatsBlock"
import Block from "../configurable/Block"
import Col8 from "../grid/Col8"
import Col6 from "../grid/Col6"
import Col4 from "../grid/Col4"
import Expandable from "../configurable/Expandable"
import QuoteColor from "../configurable/QuoteColor"
import Banner from "../configurable/Banner"
import ProductsAndWarrantiesBlock from "../configurable/ProductsAndWarrantiesBlock"
import ReviewsSlider from "~/components/standalone/ReviewsSlider"

import * as HouseIllustration from "~/vectors/house-illustration.inline.svg"
import Icon from "../olc-framework/Icon"
import TickList from "../configurable/TickList"
import { imageNodesFilter } from "~/utils"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Col9 from "../grid/Col9"
import Col11 from "../grid/Col11"
import B2CHero from "~/components/configurable/B2CHero"
import "./index.scss"


const IS_HALLOWEEN = true;

const Homepage = ({ markdownNodes, imageNodes }) => {
  const [heroImage, setHeroImage] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    //console.log("Homepage")
    setCustomerType("domestic")

    setHeroImage(imageNodesFilter(imageNodes, "homepage-video.jpg"))

    setLoading(false)
  }, [imageNodes])

  return (
    <div className="homepage" style={{ opacity: loading ? 0 : 1 }}>
      {/* <Banner style={{backgroundColor:'#70b33b'}}>
        <p style={{marginTop:'.5em'}}>
          Green Energy Together joins the nation in mourning the passing of Her Majesty Queen Elizabeth II. 
        </p>
        <p style={{marginTop:'.7em', marginBottom:'.5em'}}>
          We will remember her through keeping alive the principles and values she embodied.   
        </p>
      </Banner>  */}
      <div className="top-sec-wr">
        <div style={{ backgroundColor: '#3c96c5' }} >
          <B2CHero />
        </div>
        <QuoteColor/>
      </div>
      <Block className="homepage-content-wrapper">
        <div className="container container--column illustrated-house-block">
          <Heading underlined>A complete solar PV system for your home</Heading>
          <p>
            We believe that solar shouldn’t be a luxury, it should be for
            everyone. Our solutions are designed to be simple, easy to
            understand, with no hidden costs or additions. We provide a
            fantastic service, which is what's important to homeowners, and
            always at a fair price. We'll help you understand the best solution
            so that it will be easy to a make simple and informed choice.
          </p>
          <HouseIllustration className="house-illustration" />
          <div className="row">
            <Col8>
              <Heading level={3}>The best solution for your Home</Heading>
              <p>
                We provide smart solutions for your home by treating
                microgeneration seriously; we  apply simple design principles to
                get solar just right for you and your home. So what makes our
                offering special?
              </p>
              <p>
                Our tailored system design, with the battery fully integrated,
                is always based on your consumption and lifestyle profile to
                give you the best possible return on your investment. Our
                service and aftercare package is a streamlined and stress-free
                process designed to protect your asset and peace of mind.
              </p>
              <Expandable
                readmore={
                  <>
                    <div className="visible-xs" style={{ height: 40 }} />
                    <div className="row">
                      <Col6 className="u-layout">
                        <div
                          className="icon__circle-wrapper"
                          style={{ marginBottom: 40, fontSize: 40 }}
                        >
                          <Icon alias="solar-house" />
                        </div>
                        <p>
                          Our designs produce peak return on your solar
                          investment because we base our recommendations on your
                          lifestyle and home energy consumption.  We integrate
                          the battery from the start and use only market leading
                          software PV*SOL for accurate forecasting. This means
                          you'll generate the optimum level of energy for your
                          home, saving you money.
                        </p>
                      </Col6>
                      <Col6 className="u-layout">
                        <div
                          className="icon__circle-wrapper"
                          style={{ marginBottom: 40, fontSize: 40 }}
                        >
                          <Icon alias="solar-power" />
                        </div>
                        <p>
                          From quoting and design to our comprehensive aftercare
                          package, we provide a complete solution. We'll take
                          care of the boring bits for you, including
                          commissioning and free registration, and for 2 years
                          we offer free operation and maintenance as well as
                          monitoring your system remotely, using data for the
                          best system performance.
                        </p>
                      </Col6>
                    </div>
                  </>
                }
              />
            </Col8>
            <Col4>
              <TickList nolines blueticks>
                <li>Usage-based design</li>
                <li>Transparent data analysis</li>
                <li>Streamlined and stress free process</li>
                <li>Market leading after care &amp; warranty package</li>
                <li>Large product selection at a fair price</li>
              </TickList>
            </Col4>
          </div>
        </div>
      </Block>
      <div className="container stats-container">
        <StatsBlock device="desktop" />
        <StatsBlock device="mobile" />
      </div>
      <Block>
        <div className="container">
          <ProductsAndWarrantiesBlock imageNodes={imageNodes} />
        </div>
      </Block>
     

      <Block>
        <div className="container">
          {/* <Heading underlined>Case Studies and testimonials</Heading>
          <p>
            At Green Energy Together, we aim to go beyond the traditional notion
            of an installer. Our designs are not only cost effective - due to
            our expertise, including over 60,000 unique designs, we can create
            bespoke solutions that exceed expectations. Check out what our
            customers have to say and some of our favourite projects.
          </p>
          <p>
            <Link
              to="/projects"
              style={{
                color: "#70b33b",
                fontWeight: "bold",
              }}
            >
              <span style={{ textDecoration: "underline" }}>
                All Case Studies
              </span>
              <Icon style={{ marginLeft: "5px" }} alias="long-arrow" />
            </Link>
          </p>
          <CaseStudiesMap
            markdownNodes={markdownNodes}
            customerType="domestic"
          /> */}
               
        
          <div className="container" >
      
            <div>
              <div className="row" >
                <Col9>

                  <Heading level={3}>

                    Installation process</Heading>
                </Col9>
                <Col11>
                  <p>
                    To find out more about the process of going Solar with Green Energy Together, check out the video below. </p>
                </Col11>
              </div>
            </div >
            <div className="row" >
              <Col9 style={{ margin: "0 auto", marginBottom: "-80px", padding: "20px" }} >
                <div
                  className="video"
                  style={{
                    marginTop: "50px",
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    src={`https://www.youtube.com/embed/15Lw9TVyJ_o`}
                    frameBorder="0"
                  />
                </div>
              </Col9>
            </div>
          </div>
        </div>
      </Block>
      {/* <ReviewsSlider/> */}

    </div>
    
  )
}

export default Homepage
