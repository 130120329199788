import React, { FunctionComponent } from "react"
import "./styles.scss"
import Animate from "~/components/olc-framework/Animate"
import Heading from "../Heading"
import BlockCTA from "../../configurable/BlockCTA"
import * as Hand from "~/vectors/hand.inline.svg"

const B2CHero: FunctionComponent<any> = ({ className = "", children, ...props }) => {
  return (
    <Animate
      properties={["opacity", "transform"]}
      startValues={["0", "translateY(40px) rotate(0.5deg)"]}
      endValues={["1", "translateY(0) rotate(0deg)"]}
      delay="0s"
    >
      <div className="b2cContainerHero">
        <div className="b2cHero">
          <div className="b2cHero__content">
            <div className="b2cHero__text">
              <Heading className="b2cHero__text__h1" >
              Solar for your Home
              </Heading>
              <p className="b2cHero__text__p">
              Providing a greener future for you and your home. 
              </p>
              <BlockCTA navy url={'/quote'} arrow="right" className="comHero__image__button">
                  Get a quote
              </BlockCTA>
            </div>
            <div className="b2cHero__image">
                <div className="b2cHero__image__pad">
                  <Hand/>
                </div>
                <div className="b2cHero__image__backg"/>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default B2CHero
